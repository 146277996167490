import React from 'react';
import './App.css';
import Navigation from './components/Navigation';
import { ContextWrapper } from './contexts/ContextWrapper';
import { ConfigProvider, theme } from 'antd';

function App() {
  return (
    <ContextWrapper>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            margin: 8,
            borderRadius: 8,
            colorPrimary: '00a994',
          },
        }}
      >
        <Navigation />
      </ConfigProvider>
    </ContextWrapper>
  );
}

export default App;

import React, { useState } from 'react';

export const AppContext = React.createContext(null);

export const ContextWrapper = (props) => {
  const [store, setStore] = useState({
    user: {},
    status: 0,
    language: 'de',
  });
  const [actions, setActions] = useState({
    setPathname: (test) => setStore((prevState) => ({ ...prevState, currentPath: test })),
    setLanguage: (language) => setStore((prevState) => ({ ...prevState, language: language })),
    setCategories: (categories) => setStore((prevState) => ({ ...prevState, categories: categories })),
    setLikedCategories: (likedCategories) => setStore((prevState) => ({ ...prevState, likedCategories: likedCategories })),
    setChoicesInteractions: (choicesInteractions) => setStore((prevState) => ({ ...prevState, choicesInteractions: choicesInteractions })),
    setProfileId: (profileId) => setStore((prevState) => ({ ...prevState, user: { profileId: profileId } })),
    setUser: (user) => setStore((prevState) => ({ ...prevState, user: { ...user } })),
    setStatus: (status) => setStore((prevState) => ({ ...prevState, status: status })),
  });

  return <AppContext.Provider value={{ store, actions }}>{props.children}</AppContext.Provider>;
};

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { fetchWrapper } from '../utils/fetchwrapper';
import { FacebookIcon, PinterestIcon, RedditIcon, TelegramIcon, TumblrIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import {
  FacebookShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
} from 'react-share';
import { RightCircleOutlined, LeftCircleOutlined, FireOutlined, FireFilled, ShareAltOutlined } from '@ant-design/icons';
import { AppContext } from '../contexts/ContextWrapper';
import { notification, message, Progress, Statistic, Menu, Dropdown, Tag, Skeleton } from 'antd';
import Filter from './filter/Filter';
import '../css/solo.css';

const texts = require('../js/multilanguage');

notification.config({
  placement: 'topRight',
});

const GameArea = (props) => {
  const { store, actions } = useContext(AppContext);
  const [activeCategories, setActiveCategories] = useState([]);

  const [angle, setAngle] = useState(getAngle());
  const [initialChoices, setInitialChoices] = useState({});
  const { choices, setChoices } = props.choicesState;
  const { setLastLikes } = props.lastLikesState;
  const [likedChoicesMixes, setLikedChoicesMixes] = useState([]);
  const { currentChoicesIndex, setCurrentChoicesIndex } = props.currentChoicesIndexState;
  const { choicesMixId } = props;

  const updateLastLikes = () => {
    fetchWrapper('/api/choices/latest', { method: 'GET' }).then((res) => {
      if (res.success) {
        setLastLikes(res.lastLikes);
      }
    });
  };

  const updateLikedCategories = () => {
    fetchWrapper('/api/statistics/categories', { method: 'GET' }).then((res) => {
      if (res.success) {
        actions.setLikedCategories(res.likedCategories);
      }
    });
  };

  const getShareData = () => {
    const id = choices && choices.length > 0 ? choices[currentChoicesIndex].choiceOne.choicesMix : '';
    let choiceOne = choices && choices.length > 0 ? choices[currentChoicesIndex].choiceOne[store.language] : '';
    let choiceTwo = choices && choices.length > 0 ? choices[currentChoicesIndex].choiceTwo[store.language] : '';

    if (choiceOne[choiceOne.length - 1] === '.') {
      choiceOne = choiceOne.substring(0, choiceOne.length - 1);
    }

    if (choiceTwo[choiceTwo.length - 1] === '.') {
      choiceTwo = choiceTwo.substring(0, choiceTwo.length - 1);
    }

    const shareLink = window.location.origin + '/Choices/' + id;
    const title = `${choiceOne} ${texts.home.or[store.language]} ${choiceTwo}? ${texts.home.pestorcholera[store.language]}`;
    const questions = `${choiceOne} ${texts.home.or[store.language]} ${choiceTwo}?`;

    return {
      shareLink,
      title,
      questions,
    };
  };

  const shareMenu = () => {
    const { shareLink, title } = getShareData();

    const shareButtons = [
      {
        label: (
          <FacebookShareButton url={shareLink} quote={title}>
            <div className='share'>
              <FacebookIcon className='shareIconMargin' size={32} round={true} />
              {texts.home.share[store.language]} Facebook
            </div>
          </FacebookShareButton>
        ),
        key: 'facebook-share',
      },
      {
        label: (
          <PinterestShareButton url={shareLink} description={title}>
            <div className='share'>
              <PinterestIcon className='shareIconMargin' size={32} round={true} />
              {texts.home.share[store.language]} Pinterest
            </div>
          </PinterestShareButton>
        ),
        key: 'pinterest-share',
      },
      {
        label: (
          <TelegramShareButton url={shareLink} title={title}>
            <div className='share'>
              <TelegramIcon className='shareIconMargin' size={32} round={true} />
              {texts.home.share[store.language]} Telegram
            </div>
          </TelegramShareButton>
        ),
        key: 'telegram-share',
      },
      {
        label: (
          <WhatsappShareButton url={shareLink} title={title}>
            <div className='share'>
              <WhatsappIcon className='shareIconMargin' size={32} round={true} />
              {texts.home.share[store.language]} Whatsapp
            </div>
          </WhatsappShareButton>
        ),
        key: 'whatsapp-share',
      },
      {
        label: (
          <TumblrShareButton url={shareLink} title={title}>
            <div className='share'>
              <TumblrIcon className='shareIconMargin' size={32} round={true} />
              {texts.home.share[store.language]} Tumblr
            </div>
          </TumblrShareButton>
        ),
        key: 'tumblr-share',
      },
      {
        label: (
          <RedditShareButton url={shareLink} title={title}>
            <div className='share'>
              <RedditIcon className='shareIconMargin' size={32} round={true} />
              {texts.home.share[store.language]} Reddit
            </div>
          </RedditShareButton>
        ),
        key: 'reddit-share',
      },
      {
        label: (
          <TwitterShareButton url={shareLink} title={title}>
            <div className='share'>
              <TwitterIcon className='shareIconMargin' size={32} round={true} />
              {texts.home.share[store.language]} Twitter
            </div>
          </TwitterShareButton>
        ),
        key: 'twitter-share',
      },
    ];

    return shareButtons;
  };

  const shareNative = () => {
    const data = getShareData();

    if (navigator.share) {
      navigator.share({
        title: 'Pest or Cholera',
        text: data.questions,
        url: data.shareLink,
      });
    }
  };

  const exchangeChoices = (nextOrPrevious) => {
    if (nextOrPrevious === 'previous') {
      setCurrentChoicesIndex(currentChoicesIndex - 1);
    } else {
      setCurrentChoicesIndex(currentChoicesIndex + 1);
    }
  };

  function getAngle() {
    let controlsWidth = 0;

    if (window.innerWidth > 1000) {
      controlsWidth = window.innerWidth / 2;
    } else {
      controlsWidth = window.innerWidth;
    }

    return (Math.atan((((((window.innerHeight - 65) / 100) * 46) / 100) * 15) / controlsWidth) * 180) / Math.PI;
  }

  function setNewAngle() {
    setAngle(getAngle());
  }

  function handleResize() {
    setNewAngle();
  }

  window.addEventListener('resize', handleResize);

  const getChoices = useCallback(
    (categories) => {
      fetchWrapper('/api/choices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: choicesMixId, categories: categories }),
      })
        .then(function (response) {
          if (response.success) {
            setCurrentChoicesIndex(0);
            setChoices([...response.choices]);
            setInitialChoices([...response.choices]);
          } else {
            message.error('Choices could not be loaded.');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [choicesMixId, setChoices, setCurrentChoicesIndex],
  );

  const updateChoices = (choices) => {
    if (store && store.user && store.user.likedChoicesMixes && Array.isArray(choices)) {
      for (let j = 0; j < choices.length; j++) {
        if (store.user.likedChoicesMixes.includes(choices[j]._id)) {
          if (store.user.likedChoices.includes(choices[j].choiceOne._id)) {
            choices[j].choiceOne.liked = true;
          } else if (store.user.likedChoices.includes(choices[j].choiceTwo._id)) {
            choices[j].choiceTwo.liked = true;
          }
        }
      }

      setChoices([...choices]);
    }
  };

  const setRawCategories = (categories) => {
    const rawCategoriesTemp = [];
    categories.forEach((category) => {
      rawCategoriesTemp.push(category._id);
    });

    setActiveCategories(rawCategoriesTemp);
  };

  useEffect(() => {
    if (store.user && store.user.profileId) {
      updateChoices(initialChoices);
    }
  }, [initialChoices, store.user]);

  useEffect(() => {
    if (store.user && store.user.profileId) {
      updateLastLikes();
      updateLikedCategories();
    }
  }, [store.user]);

  useEffect(() => {
    if (likedChoicesMixes.length > 0 && store.user && store.user.profileId) {
      updateLastLikes();
      updateLikedCategories();
    }
  }, [likedChoicesMixes]);

  useEffect(() => {
    if (store.user && store.user.profileId && !store.categories) {
      fetchWrapper('/api/category', { method: 'GET' }).then((response) => {
        if (response.success && response.categories.length > 0) {
          actions.setCategories(response.categories);

          setRawCategories(response.categories);
        }
      });
    }
  }, [store.user, actions, store.categories]);

  useEffect(() => {
    setNewAngle();

    if (store && store.categories) {
      setRawCategories(store.categories);
    }
  }, []);

  useEffect(() => {
    if (activeCategories.length > 0) {
      getChoices(activeCategories);
    }
  }, [activeCategories, getChoices]);

  const renderTags = () => {
    if (choices[currentChoicesIndex] && choices[currentChoicesIndex].categories) {
      return (
        <div className='categories'>
          {choices[currentChoicesIndex].categories.map((category, index) => {
            return (
              <Tag
                onClick={() => {
                  setActiveCategories([category._id]);
                }}
                key={`tag-${index}`}
                className='category'
              >
                {category[store.language]}
              </Tag>
            );
          })}
        </div>
      );
    }
  };

  const setColorsCss = () => {
    const currentChoicesMix = choices[currentChoicesIndex];
    if (currentChoicesMix && currentChoicesMix['choiceOne'] && currentChoicesMix['choiceTwo']) {
      let root = document.documentElement;

      root.style.setProperty('--primary-color', currentChoicesMix['choiceOne'].color);
      root.style.setProperty('--secondary-color', currentChoicesMix['choiceTwo'].color);
    }
  };

  const like = (choice) => {
    fetchWrapper('/api/choices/like', {
      method: 'POST',
      body: JSON.stringify({ choiceId: choices[currentChoicesIndex][choice]._id }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        if (response.success) {
          actions.setUser({ ...store.user, amountLikedChoices: store.user.amountLikedChoices + 1 });
          choices[currentChoicesIndex][choice].liked = true;
          choices[currentChoicesIndex][choice].upVotes += 1;
          updateChoices([...choices]);
          setLikedChoicesMixes(response.likedChoicesMixes);
        } else {
          message.error('Choices could not be loaded.');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getShareButtons = () => {
    if (props.multiplayer) return;

    if (navigator.share) {
      return (
        <div className='shareButton bottomLeft'>
          <ShareAltOutlined onClick={() => shareNative()}></ShareAltOutlined>
        </div>
      );
    } else {
      return (
        <div className='shareButton bottomLeft'>
          <Dropdown placement='topLeft' arrow trigger={['click']} menu={{ items: shareMenu() }}>
            <ShareAltOutlined></ShareAltOutlined>
          </Dropdown>
        </div>
      );
    }
  };

  const getReportingData = (classNames, choice, choiceColor) => {
    if (choices && choices.length > 0 && (choices[currentChoicesIndex]['choiceOne'].liked || choices[currentChoicesIndex]['choiceTwo'].liked)) {
      var choiceVotes = choices[currentChoicesIndex][choice].upVotes;

      return <Statistic className={classNames} value={choiceVotes} valueStyle={{ color: 'white' }} prefix={<FireOutlined />} />;
    }
  };

  const getReporting = (classNames, choice, choiceColor) => {
    if (choices && choices.length > 0 && (choices[currentChoicesIndex]['choiceOne'].liked || choices[currentChoicesIndex]['choiceTwo'].liked)) {
      var totalVotes = choices[currentChoicesIndex]['choiceOne'].upVotes + choices[currentChoicesIndex]['choiceTwo'].upVotes;
      var percentageOne = Math.round((choices[currentChoicesIndex]['choiceOne'].upVotes / totalVotes) * 100);
      return (
        <div id='reportingWrapper'>
          <Progress
            format={(percent) => (
              <div>
                <div
                  style={{
                    color: choices[currentChoicesIndex]['choiceOne'].color,
                    fontSize: '2vh',
                  }}
                >
                  {percentageOne}%
                </div>
                <div style={{ color: '#5c5c5c', padding: 2, fontSize: '2vh' }}>VS</div>
                <div
                  style={{
                    color: choices[currentChoicesIndex]['choiceTwo'].color,
                    fontSize: '2vh',
                  }}
                >
                  {100 - percentageOne}%
                </div>
              </div>
            )}
            strokeLinecap='square'
            trailColor={choices[currentChoicesIndex]['choiceTwo'].color}
            type='circle'
            percent={percentageOne}
            strokeColor={choices[currentChoicesIndex]['choiceOne'].color}
          />
        </div>
      );
    }
  };

  const getLikeButton = (classNames, choice, choiceColor) => {
    if (choices && choices.length > 0 && (choices[currentChoicesIndex]['choiceOne'].liked || choices[currentChoicesIndex]['choiceTwo'].liked)) {
      if (choices && choices.length > 0 && choices[currentChoicesIndex][choice].liked) {
        return (
          <FireFilled
            className={classNames}
            style={{
              color: choices && choices.length > 0 ? choices[currentChoicesIndex][choiceColor].color : '#FFFFFF',
            }}
          />
        );
      }
    } else {
      return (
        <FireOutlined
          onClick={() => like(choice)}
          className={classNames}
          style={{
            color: choices && choices.length > 0 ? choices[currentChoicesIndex][choiceColor].color : '#FFFFFF',
          }}
        />
      );
    }
  };

  const getControls = () => {
    if (props.multiplayer !== true) {
      return (
        <div>
          <LeftCircleOutlined
            style={{
              margin: 5,
              visibility: currentChoicesIndex === 0 ? 'hidden' : 'visible',
              color: choices && choices.length > 0 ? choices[currentChoicesIndex].choiceOne.color : '#FFFFFF',
            }}
            className='gameControls'
            onClick={() => exchangeChoices('previous')}
          />

          <RightCircleOutlined
            style={{
              visibility: currentChoicesIndex === choices.length - 1 ? 'hidden' : 'visible',
              color: choices && choices.length > 0 ? choices[currentChoicesIndex].choiceTwo.color : '#FFFFFF',
            }}
            className='gameControls'
            onClick={() => exchangeChoices('next')}
          />
        </div>
      );
    }
  };
  setColorsCss();

  return (
    <div style={{ height: '100%' }}>
      <div className='big' onDoubleClick={() => like('choiceOne')}>
        <Filter activeCategories={activeCategories} setActiveCategories={setActiveCategories}></Filter>

        {renderTags()}

        {getReportingData('statistics statisticsRight', 'choiceOne', 'choiceTwo')}
        {getLikeButton('likeButton bottomLeft', 'choiceOne', 'choiceTwo')}

        <svg
          id='svgOne'
          width='100%'
          height='100%'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
          style={{
            width: '100%',
            padding: 0,
            margin: 0,
            fill: choices && choices.length > 0 ? choices[currentChoicesIndex].choiceOne.color : 'rgb(70, 32, 102)  ',
            position: 'absolute',
          }}
        >
          <polygon points='0,0 0,100 100,85 100,0'></polygon>
        </svg>
        <div className='contentWrapper'>
          <div>{choices && choices.length > 0 ? choices[currentChoicesIndex].choiceOne[store.language] : <Skeleton className='skeleton' active />}</div>
        </div>
      </div>
      <div
        className='small gameControlsContainer'
        style={{
          transform: 'rotate(-' + angle + 'deg)',
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        {getReporting('bottomLeft', 'choiceOne', 'choiceTwo')}

        {getControls()}
      </div>

      <div className='big' onDoubleClick={() => like('choiceTwo')}>
        {getShareButtons()}
        {getReportingData('statistics statisticsLeft', 'choiceTwo', 'choiceOne')}
        {getLikeButton('likeButton topRight', 'choiceTwo', 'choiceOne')}
        {choices && choices.length > 0 ? (
          <div className='choicesCounterWrapper'>
            <div>
              {currentChoicesIndex + 1} / {choices.length}
            </div>
          </div>
        ) : (
          <></>
        )}
        <svg
          id='svgTwo'
          width='100%'
          height='100%'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
          style={{
            width: '100%',
            padding: 0,
            margin: 0,
            fill: choices && choices.length > 0 ? choices[currentChoicesIndex].choiceTwo.color : 'rgb(0, 170, 160)',
            position: 'absolute',
          }}
        >
          <polygon points='0,15 0,100 100,100 100,0'></polygon>
        </svg>
        <div className='contentWrapper'>
          <div>{choices && choices.length > 0 ? choices[currentChoicesIndex].choiceTwo[store.language] : <Skeleton className='skeleton' active />}</div>
        </div>
      </div>
    </div>
  );
};

export default GameArea;

import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Drawer } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../js/helper';
import { AppContext } from '../contexts/ContextWrapper';
import texts from '../js/multilanguage';
import { resetPassword } from '../js/authHelper';

import '../css/login.css';

const ForgotPassword = (props) => {
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { store, actions } = useContext(AppContext);

  const onFinish = (values) => {
    setLoading(true);
    resetPassword(values);
    props.drawers.passwordForgot.openPasswordForgotDrawer(false);
  };

  const changeHandler = (e) => {
    setFormErrors({});

    let currentFormValues = form.getFieldsValue();

    if (typeof currentFormValues.email === 'undefined' || currentFormValues.email === '') {
      form.resetFields();
    }
  };

  // useEffect(() => {
  //   if (store.user) {
  //     //props.auth.user.passwordResetting
  //     setLoading(false);
  //     form.resetFields();
  //     props.drawers.passwordForgot.openPasswordForgotDrawer(false);
  //     openNotificationWithIcon('success', 'Success', 'You will recieve an email with a link to reset your password.');
  //   }
  // }, [store.user]);

  // useEffect(() => {
  //   setFormErrors(props.errors.forgotPassword);
  //   setLoading(false);
  // }, [props.errors.forgotPassword]);

  useEffect(() => {
    let currentFormValues = form.getFieldsValue();
    if (typeof currentFormValues.email !== 'undefined' && currentFormValues.email !== '') {
      form.validateFields(['email']);
    }
  }, [formErrors]);

  return (
    <Drawer
      forceRender
      getContainer={false}
      width={300}
      title='Reset Password'
      placement='right'
      closable={false}
      onClose={() => props.drawers.passwordForgot.openPasswordForgotDrawer(false)}
      open={props.drawers.passwordForgot.passwordForgotDrawer}
    >
      <Form
        form={form}
        size='middle'
        name='password_reset'
        className='login-form'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name='email'
          type='email'
          validateFirst={true}
          rules={[
            {
              required: true,
              message: texts.userManagement.mailRequired[store.language],
            },
            {
              type: 'email',
              message: texts.userManagement.mailInvalid[store.language],
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (formErrors && 'message' in formErrors) {
                  return Promise.reject(formErrors.message);
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input onChange={changeHandler} prefix={<UserOutlined />} placeholder='Email' />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type='primary' htmlType='submit' className='login-form-button'>
            Reset password
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ForgotPassword;

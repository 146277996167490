import React, { useState, useEffect } from 'react';
import { notification, message, Table, Input, Space, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { calculateScore } from '../js/helper';
import '../css/home.css';
import '../css/table.css';
import { LikeOutlined, CommentOutlined, FireOutlined, TrophyOutlined, OrderedListOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { fetchWrapper } from '../utils/fetchwrapper';

notification.config({
  placement: 'topRight',
});

const UserRanking = (props) => {
  const [userData, setuserData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size='small' style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    fetchWrapper('/api/users', { method: 'GET' })
      .then(function (response) {
        if (response.success) {
          let userData = [];

          for (var i = 0; i < response.users.length; i++) {
            let currentUserRow = response.users[i];
            let userScore = calculateScore(
              currentUserRow.amountCommentsWritten,
              currentUserRow.amountLikedChoices,
              currentUserRow.amountCommentsUpvoted,
              currentUserRow.amountChoicesCreated,
            );

            userData.push({ ...currentUserRow, score: userScore });
          }

          userData.sort((a, b) => (a.score < b.score ? 1 : -1));

          userData.forEach((userData, index) => {
            userData.rank = index + 1;
            userData.key = 'row_' + index;
          });

          setuserData(userData);
        } else {
          message.error('Choices could not be loaded.');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const sortAlphabetically = (a, b) => {
    if (a.choiceOne.toLowerCase() < b.choiceOne.toLowerCase()) {
      return -1;
    }
    if (a.choiceOne.toLowerCase() > b.choiceOne.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sortAlphabetically(a, b),
      width: 130,
      ...getColumnSearchProps('name'),
    },
    {
      title: <OrderedListOutlined></OrderedListOutlined>,
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a, b) => a.rank - b.rank,
      width: 50,
    },
    {
      title: <TrophyOutlined></TrophyOutlined>,
      dataIndex: 'score',
      key: 'score',
      sorter: (a, b) => a.score - b.score,
      width: 50,
    },
    {
      title: <FireOutlined></FireOutlined>,
      dataIndex: 'amountLikedChoices',
      key: 'amountLikedChoices',
      sorter: (a, b) => a.amountLikedChoices - b.amountLikedChoices,
      width: 50,
    },
    {
      title: <CommentOutlined></CommentOutlined>,
      dataIndex: 'amountCommentsWritten',
      key: 'amountCommentsWritten',
      sorter: (a, b) => a.amountCommentsWritten - b.amountCommentsWritten,
      width: 50,
    },
    {
      title: <LikeOutlined></LikeOutlined>,
      dataIndex: 'amountCommentsUpvoted',
      key: 'amountCommentsUpvoted',
      sorter: (a, b) => a.amountCommentsUpvoted - b.amountCommentsUpvoted,
      width: 50,
    },
    {
      title: <EditOutlined></EditOutlined>,
      dataIndex: 'amountChoicesCreated',
      key: 'amountChoicesCreated',
      sorter: (a, b) => a.amountChoicesCreated - b.amountChoicesCreated,
      width: 50,
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  return (
    <div className='tableContainer'>
      <Table
        className='table'
        style={{ background: 'rgba(255, 255, 255, 0.2)' }}
        size='small'
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: 100 }}
        tableLayout='auto'
        bordered
        pagination={false}
        dataSource={userData}
        columns={columns}
      />
    </div>
  );
};

export default UserRanking;

import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Checkbox, Drawer } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { AppContext } from '../contexts/ContextWrapper';
import '../css/login.css';
import { loginUser } from '../js/authHelper';
import { Link } from 'react-router-dom';

const texts = require('../js/multilanguage');
const Login = (props) => {
  const { store, actions } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState({});

  const closePanel = () => {
    props.drawers.login.openLoginDrawer(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    values.password = btoa(values.password);
    loginUser(values, actions, closePanel);
    //props.loginUser(values, closePanel);
  };

  const changeHandler = (e) => {
    setFormErrors({});

    let currentFormValues = form.getFieldsValue();

    if (
      (typeof currentFormValues.email === 'undefined' || currentFormValues.email === '') &&
      (typeof currentFormValues.password === 'undefined' || currentFormValues.password === '')
    ) {
      form.resetFields();
    }
  };

  useEffect(() => {
    setFormErrors(props.errors);
  }, [props.errors]);

  useEffect(() => {
    let currentFormValues = form.getFieldsValue();

    if (typeof currentFormValues.email !== 'undefined' && currentFormValues.email !== '') {
      form.validateFields(['email']);
    }

    if (typeof currentFormValues.password !== 'undefined' && currentFormValues.password !== '') {
      form.validateFields(['password']);
    }
  }, [formErrors]);

  return (
    <Drawer
      forceRender
      getContainer={false}
      width={300}
      title={texts.userManagement.login.text[store.language]}
      placement='right'
      closable={false}
      onClose={() => props.drawers.login.openLoginDrawer(false)}
      open={props.drawers.login.loginDrawer}
    >
      <Form
        size='middle'
        name='login'
        className='login-form'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='email'
          type='email'
          validateFirst={true}
          rules={[
            {
              required: true,
              message: texts.userManagement.mailRequired[store.language],
            },
            {
              type: 'email',
              message: texts.userManagement.mailInvalid[store.language],
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if ('emailnotfound' in formErrors) {
                  return Promise.reject(formErrors.emailnotfound);
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input onChange={changeHandler} prefix={<UserOutlined />} placeholder='Email' />
        </Form.Item>
        <Form.Item
          name='password'
          validateFirst={true}
          rules={[
            {
              required: true,
              message: texts.userManagement.passwordRequired[store.language],
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if ('passwordincorrect' in formErrors) {
                  return Promise.reject(formErrors.passwordincorrect);
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input onChange={changeHandler} prefix={<LockOutlined />} type='password' placeholder={texts.userManagement.password[store.language]} />
        </Form.Item>

        <Form.Item name='remember' valuePropName='checked' noStyle>
          <Checkbox>{texts.userManagement.login.rememberMe[store.language]}</Checkbox>
        </Form.Item>
        <Form.Item>
          <a
            className='login-form-forgot'
            onClick={() => {
              props.drawers.login.openLoginDrawer(false);
              props.drawers.passwordForgot.openPasswordForgotDrawer(true);
            }}
          >
            {texts.userManagement.login.passwordForgot[store.language]}
          </a>
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' className='login-form-button'>
            {texts.userManagement.login.text[store.language]}
          </Button>
          <div style={{ textAlign: 'center', margin: '4px' }}>{texts.home.or[store.language]}</div>
          <Button
            type='secondary'
            className='login-form-button'
            onClick={() => {
              props.drawers.login.openLoginDrawer(false);
              props.drawers.register.openRegisterDrawer(true);
            }}
          >
            {texts.userManagement.signUp.text[store.language]}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Login;

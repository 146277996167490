import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/ContextWrapper';
import { Typography } from 'antd';
import texts from '../../../js/multilanguage';
import { fetchWrapper } from '../../../utils/fetchwrapper';
import './userInteractions.css';
import ActivityCalendar from 'react-activity-calendar';

import ReactTooltip from 'react-tooltip';
const { Title } = Typography;

const UserInteractions = () => {
  const { store, actions } = useContext(AppContext);
  const [values, setValues] = useState([]);

  const updateUserInteractions = () => {
    if (store.user)
      fetchWrapper(`/api/statistics/userInteractions`, { method: 'GET' }).then((res) => {
        if (res.success) {
          const currentDate = new Date();
          if (res.interactionsHistory.length > 0) {
            if (res.interactionsHistory[0].date !== `${currentDate.getFullYear()}-01-01`)
              res.interactionsHistory.splice(0, 0, { level: 0, count: 0, date: `${currentDate.getFullYear()}-01-01` });

            if (res.interactionsHistory[res.interactionsHistory.length - 1].date !== `${currentDate.getFullYear()}-12-31`)
              res.interactionsHistory.push({ level: 0, count: 0, date: `${currentDate.getFullYear()}-12-31` });
          } else {
            res.interactionsHistory.push({ date: `${currentDate.getFullYear()}-01-01`, count: 0 });
            res.interactionsHistory.push({ date: `${currentDate.getFullYear()}-12-31`, count: 0 });
          }

          res.interactionsHistory.forEach((interaction) => {
            if (interaction.count >= 1 && interaction.count < 10) interaction.level = 1;
            else if (interaction.count >= 10 && interaction.count < 20) interaction.level = 2;
            else if (interaction.count >= 20 && interaction.count < 30) interaction.level = 3;
            else if (interaction.count >= 30 && interaction.count < 40) interaction.level = 4;
          });

          setValues(res.interactionsHistory);
        }
      });
  };

  useEffect(() => {
    updateUserInteractions();
  }, [store.user.interactionsHistory, store.user.amountLikedChoices]);

  return (
    <div className='user-interactions__wrapper'>
      <Title level={3}>{texts.choicesInteractions.header[store.language]}</Title>
      <ActivityCalendar
        dateFormat={texts.choicesInteractions.dateFormat[store.language]}
        data={values}
        labels={{
          legend: {
            less: texts.choicesInteractions.less[store.language],
            more: texts.choicesInteractions.more[store.language],
          },
          months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          tooltip: texts.choicesInteractions.tooltip[store.language],
          totalCount: texts.choicesInteractions.totalCount[store.language],
        }}
        theme={{
          level0: '#F0F0F0',
          level1: '#72efdd',
          level2: '#56cfe1',
          level3: '#4ea8de',
          level4: '#5e60ce',
        }}
      >
        <ReactTooltip html />
      </ActivityCalendar>
    </div>
  );
};

export default UserInteractions;

import React, { useContext } from 'react';
import { AppContext } from '../contexts/ContextWrapper';
import { Typography, List, Row, Col } from 'antd';
import { FireFilled, FireOutlined } from '@ant-design/icons';
import texts from '../js/multilanguage';
const { Title } = Typography;

const getLikeStatus = (like) => {
  if (like) return <FireFilled></FireFilled>;

  return <FireOutlined></FireOutlined>;
};

const LastViewedChoices = (props) => {
  const { store, actions } = useContext(AppContext);
  const { lastLikes } = props;

  return (
    <div>
      <Title level={3}>{texts.lastLikedChoices.header[store.language]}</Title>{' '}
      <List
        size='small'
        dataSource={lastLikes}
        renderItem={(item) => (
          <List.Item>
            <Row gutter={24} style={{ width: '100%' }}>
              <Col span={12}>
                {item.choiceOne[store.language]} {getLikeStatus(item.choiceOne.liked ? true : false)}
              </Col>
              <Col span={12}>
                {item.choiceTwo[store.language]} {getLikeStatus(item.choiceTwo.liked ? true : false)}
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </div>
  );
};

export default LastViewedChoices;

import { message } from 'antd';

import { clearObject } from '../js/helper';
const updateOptions = (options) => {
  const update = { ...options };
  update.headers = clearObject({
    ...update.headers,
    Authorization: localStorage.pestorcholera_token ? `Bearer ${localStorage.pestorcholera_token}` : undefined,
  });
  return update;
};

export const fetchWrapper = (url, options) => {
  return fetch(url, updateOptions(options))
    .then(function (response) {
      var contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      }
      throw new TypeError("Oops, we haven't got JSON!");
    })
    .then(function (json) {
      return json;
    })
    .catch(function (error) {
      message.info(error);
    });
};

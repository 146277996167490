import React, { useState, useEffect, useContext } from 'react';
import { STATUS } from '../../src/constants/enums';

import { useParams } from 'react-router-dom';
import { AppContext } from '../contexts/ContextWrapper';
import { notification, Space, message } from 'antd';
import '../css/home.css';
import Comments from '../components/Comments';
import GameArea from '../components/GameArea';
import LastViewedChoices from '../components/LastViewedChoices';
import UserInteractions from '../components/statistics/userInteractions/userInteractions';
import { loginWithCode } from '../js/authHelper';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '../js/helper';
import jwt_decode from 'jwt-decode';

const texts = require('../js/multilanguage');

notification.config({
  placement: 'topRight',
});

const Home = (props) => {
  const { store, actions } = useContext(AppContext);
  const [choices, setChoices] = useState({});
  const [lastLikes, setLastLikes] = useState([]);
  const [currentChoicesIndex, setCurrentChoicesIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof props.errors !== 'undefined' && props.errors.message) openNotificationWithIcon('error', 'Error', props.errors.message, 7);
  }, [props.errors]);

  const openNotificationWithIcon = (type, title, message, duration) => {
    if (typeof duration === 'undefined') duration = 7;

    notification[type]({
      message: title,
      description: message,
      duration: duration,
    });
  };

  const { email, verificationCode, passwordForgotCode, choicesMixId } = useParams();

  useEffect(() => {
    if (email) {
      if (verificationCode) {
        fetch('/api/auth/confirm?email=' + email + '&verificationCode=' + verificationCode, {
          method: 'get',
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === true) {
              openNotificationWithIcon('success', 'Success', texts.home.accountActivation.activated[store.language]);
            } else {
              if (data.verificationCodeIncorrect) {
                openNotificationWithIcon('error', 'Error', data.verificationCodeIncorrect);
              } else if (data.email) {
                openNotificationWithIcon('error', 'Error', data.email);
              } else {
                openNotificationWithIcon('error', 'Error', 'Could not activate account.');
              }
            }
          });
      } else if (passwordForgotCode) {
        loginWithCode({ email: email, passwordForgotCode: passwordForgotCode }, actions).then(function (result) {
          if (result.success) {
            const token = result.token;
            localStorage.setItem('pestorcholera_token', token);
            getProfile(actions, message);
            openNotificationWithIcon(
              'info',
              texts.home.passwordReset.setNewPassword,
              <div>
                {texts.home.passwordReset.header[store.language]}
                <br />
                <br />
                <b> {texts.home.passwordReset.instructions[store.language]}</b>
                <br />
                {texts.home.passwordReset.firstStep[store.language]}
                <br />
                {texts.home.passwordReset.secondStep[store.language]}
                <br />
                {texts.home.passwordReset.thirdStep[store.language]}
                <br />
              </div>,
              30,
            );
            // navigate('/');
          } else {
            message.error(result.message, 10);
          }
        });
      }

      // navigate('/');
    }
  }, []);

  return (
    <div className='col-container'>
      <div className='col colorOne'>
        <GameArea
          choicesMixId={choicesMixId}
          userData={props.userData}
          lastLikesState={{ lastLikes, setLastLikes }}
          choicesState={{ choices, setChoices }}
          currentChoicesIndexState={{ currentChoicesIndex, setCurrentChoicesIndex }}
        />
      </div>

      <div className='col colorTwo secondColumn' style={{ position: 'relative' }}>
        <Space direction='vertical' size='large' style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <LastViewedChoices lastLikes={lastLikes}></LastViewedChoices>
          <UserInteractions></UserInteractions>
          {/* <ChoicesInteractions currentChoice={choices[currentChoicesIndex]}></ChoicesInteractions> */}

          {/* <div class='statistics__wrapper' align='baseline'>
            { <LikedCategories></LikedCategories> }
          </div> */}

          <Comments currentChoice={choices[currentChoicesIndex]} />
        </Space>
      </div>
    </div>
  );
};

export default Home;

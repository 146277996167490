const texts = {
  minimumRequirements: {
    likes: { de: 'Du musst 15 Szenarien liken.', en: 'You have to like 15 choices.' },
    comments: { de: 'Du musst 10 Kommentare schreiben.', en: 'You have to write 10 comments.' },
  },

  filter: {
    title: {
      de: 'Filter',
      en: 'Filter',
    },
    apply: {
      de: 'Anwenden',
      en: 'Apply',
    },
    reset: {
      de: 'Leeren',
      en: 'Reset',
    },
    abort: {
      de: 'Abbrechen',
      en: 'Abort',
    },
    placeholder: {
      de: 'Bitte Kategorieren auswählen',
      en: 'Please select categories',
    },
    error: {
      de: 'Bitte wähle mindestens eine Kategorie aus.',
      en: 'Please select at least one category.',
    },
  },
  tableHeaders: {
    name: {
      de: 'Name',
      en: 'Name',
    },
    choiceOne: {
      de: 'Szenario',
      en: '',
    },
    choiceTwo: {
      de: '',
      en: '',
    },
    choice: {
      de: '',
      en: '',
    },
  },
  errors: {
    passwordLength: {
      de: 'Das Passwort muss mindestens aus 6 Zeichen bestehen.',
      en: 'Password has to contain at least 6 characters.',
    },
    passwordMatch: {
      de: 'Die beiden Passwörter stimmen nicht überein!',
      en: 'The two passwords that you entered do not match!',
    },
  },
  headers: {
    profile: {
      de: 'Profil',
      en: 'Profile',
    },
  },
  contextMenu: {
    logout: {
      de: 'Ausloggen',
      en: 'Logout',
    },
    createScenarios: {
      de: 'Szenarien einsenden',
      en: 'Send Scenarios',
    },
    profile: {
      de: 'Profil',
      en: 'Profile',
    },
  },
  createQuestions: {
    questionOne: {
      de: 'Erstes Szneario',
      en: 'First choice',
    },
    questionTwo: {
      de: 'Zweites Szenario',
      en: 'Second choice',
    },
    categories: {
      de: 'Kategorien',
      en: 'Categories',
    },
    createQuestionsCategories: {
      de: 'Kategorien angeben, bitte.',
      en: 'Please enter categories',
    },
    createQuestionOne: {
      de: 'Erstes Szenario angeben, bitte.',
      en: 'Please enter the first choice',
    },
    createQuestionTwo: {
      de: 'Zweites Szenario angeben, bitte.',
      en: 'Please enter the second choice',
    },
    createQuestions: {
      de: 'Szenarien einsenden',
      en: 'Send choices',
    },
    success: {
      de: 'Szenarien wurden erfolgreich übermittelt.',
      en: 'Choices have been delivered successfully.',
    },
    error: {
      de: 'Choices could not be sent.',
      en: 'Szenarien konnten nicht übermittelt werden.',
    },
  },
  comments: {
    header: {
      de: 'Kommentare',
      en: 'Comments',
    },
    notAvailable: {
      de: 'Keine Kommentare vorhanden',
      en: 'No comments yet',
    },
    top: {
      de: 'Top Kommentare',
      en: 'Top comments',
    },
    add: {
      de: 'Kommentar hinzufügen',
      en: 'Add comment',
    },
    latest: {
      de: 'Letzte Kommentare',
      en: 'Last comments',
    },
    tooShort: {
      de: 'Dein Kommentar sollte mindestens 10 Zeichen enthalten.',
      en: 'Your comment should have at least 10 characters.',
    },
    couldNotReceive: {
      de: 'Kommentare konnten nicht geladen werden',
      en: 'Comments could not be reciecved',
    },
    makeChoiceFirst: {
      de: 'Bitte treffe zuerst eine Wahl.',
      en: 'Please make a choice first.',
    },
    minimumLikes: {
      de: 'Du musst mit 15 Szenarien liken, bevor du einen Kommentar schreiben kannst.',
      en: 'You need to like 15 choices before writing a comment.',
    },
  },
  navigation: {
    editor: {
      de: 'Szenarien bearbeiten',
      en: 'Edit choices',
    },
    categoryEditor: {
      de: 'Kategorien bearbeiten',
      en: 'Edit category',
    },
    home: {
      de: 'Pest oder Cholera',
      en: 'Pest or Cholera',
    },
    userRanking: {
      de: 'Rangliste',
      en: 'Ranking',
    },
    choicesOverview: {
      de: 'Übersicht Szenarien',
      en: 'Choices Overview',
    },
    choicesEditor: {
      de: 'Szenarien Editieren',
      en: 'Edit Choices',
    },
  },
  home: {
    or: {
      de: 'oder',
      en: 'or',
    },
    pestorcholera: {
      de: 'Pest oder Cholera - Entscheide!',
      en: 'Pest or Cholera - Decide!',
    },
    share: {
      de: 'Teilen auf',
      en: 'Share on',
    },
    passwordReset: {
      header: { de: 'Bitte setze ein neues Passwort.', en: 'Please set a new password.' },
      instructions: { de: 'Anleitung:', en: 'Instructions:' },
      firstStep: {
        de: '1. Klicke auf dein Avatar auf der rechten oberen Ecke',
        en: '1. Click on the avatar in the top right corner',
      },
      secondStep: {
        de: `2. Wähle den Eintrag 'Profil'`,
        en: `2. Select the entry 'Profile'`,
      },
      thirdStep: {
        de: '3. Wähle ein neues Passwort und klicke auf speichern.',
        en: '3. Set a new password and save.',
      },
    },
    accountActivation: {
      activated: {
        de: 'Dein Account wurde erfolgreich aktiviert. Du kannst dich nun anmelden.',
        en: 'Account was activated successfully. You can now login into your account.',
      },
    },
    createAccountNotification: {
      title: {
        de: 'Bitte log dich zuerst ein. Wenn du noch keinen Account hast,<br>dann erstelle einen.',
        en: `Please login first. If you don't have an account,<br>please create one.`,
      },
      okText: {
        de: 'Registrieren',
        en: 'Register',
      },
      cancelText: {
        de: 'Anmelden',
        en: 'Login',
      },
    },
  },
  choicesOverview: {},
  userRanking: {},
  userManagement: {
    passwordRequired: {
      de: 'Bitte Passwort eingeben.',
      en: 'Please input your password.',
    },
    mailRequired: {
      de: 'Bitte E-Mail Adresse eingeben.',
      en: 'Please input your Email.',
    },
    mailInvalid: {
      de: 'Die E-Mail Adresse ist ungültig.',
      en: 'The input is not valid E-mail.',
    },
    upload: {
      de: 'Hochladen',
      en: 'Upload',
    },
    nickname: {
      de: 'Spitzname',
      en: 'Nickname',
    },
    email: {
      de: 'E-Mail',
      en: 'E-Mail',
    },
    password: {
      de: 'Passwort',
      en: 'Password',
    },
    confirmPassword: {
      de: 'Passwort wiederholen',
      en: 'Confirm password',
    },
    passwordReset: {
      de: 'Password zurücksetzen',
      en: 'Reset Password',
    },
    login: {
      rememberMe: {
        de: 'Login speichern',
        en: 'Remember me',
      },
      passwordForgot: {
        de: 'Passwort vergessen?',
        en: 'Forgot password?',
      },
      text: {
        de: 'Anmelden',
        en: 'Log In',
      },
    },
    signUp: {
      text: {
        de: 'Registrieren',
        en: 'Sign Up',
      },
      agreement: {
        de: `Ich habe die AGB's gelesen.`,
        en: 'I have read the agreement.',
      },
    },
  },
  chat: {
    messagePlaceholder: {
      en: 'Send a message...',
      de: 'Sende eine Nachricht...',
    },
    sendMessage: {
      en: 'Send',
      de: 'Senden',
    },
  },
  multiplayer: {
    panelHeader: {
      de: 'Mit Freunden spielen',
      en: 'Play with friends',
    },
    username: {
      de: 'Benutzername',
      en: 'Username',
    },
    gameId: {
      de: 'Spiel Id',
      en: 'Game Id',
    },
    create: {
      de: 'Spiel erstellen',
      en: 'Create game',
    },
    join: {
      de: 'Spiel beitreten',
      en: 'Join game',
    },
  },
  lastLikedChoices: {
    header: {
      de: 'Letzte likes',
      en: 'Last likes',
    },
  },
  likedCategories: {
    header: {
      de: 'Kategorie Interaktionen',
      en: 'Category Interactions',
    },
  },
  choicesInteractions: {
    header: {
      de: 'Szenarien Interaktionen',
      en: 'Choices Interactions',
    },
    tooltip: {
      de: '<strong>{{count}} Interaktionen</strong> am {{date}}',
      en: '<strong>{{count}} interactions</strong> on {{date}}',
    },
    totalCount: {
      de: 'In {{year}} mit {{count}} Szenarien interagiert.',
      en: 'Interacted with {{count}} choices in {{year}}.',
    },
    less: {
      de: 'Wenig',
      en: 'Less',
    },
    more: {
      de: 'Mehr',
      en: 'More',
    },
    dateFormat: {
      de: 'dd.MM.yyyy',
      en: 'MMM do, yyyy',
    },
  },
};

module.exports = texts;

import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../contexts/ContextWrapper';
import Login from './Login';
import Register from './Register';
import CreateQuestions from './CreateQuestions';
import Profile from './Profile';
import '../css/navbar.css';
import jwt_decode from 'jwt-decode';
import {
  HomeOutlined,
  PlusOutlined,
  LogoutOutlined,
  UserOutlined,
  ReadOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  MenuFoldOutlined,
  GlobalOutlined,
  EditOutlined,
  TagOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import { Button, Menu, Dropdown, Layout, Avatar, message, Space } from 'antd';

import { Typography } from 'antd';

import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import Home from '../pages/Home';
import ChoicesOverview from '../pages/ChoicesOverview';
import UserRanking from '../pages/UserRanking';
import ChoicesEditor from '../pages/ChoicesEditor/ChoicesEditor';
import ForgotPassword from './ForgotPassword';
import texts from '../js/multilanguage';
import { getProfile } from '../js/helper';
import { STATUS } from '../constants/enums';
import { createUser, logoutUser } from '../js/authHelper';
import CategoryEditor from '../pages/CategoryEditor/CategoryEditor';

const { Text } = Typography;
const { Content, Header, Sider } = Layout;

const Navigation = (props) => {
  props = { errors: { forgotPassword: { test: '' } } };
  const setHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  setHeight();

  window.addEventListener('resize', () => {
    // setHeight();
  });

  const { store, actions } = useContext(AppContext);
  const [navigationDrawer, openNavigationDrawer] = useState(false);
  const [loginDrawer, openLoginDrawer] = useState(false);
  const [registerDrawer, openRegisterDrawer] = useState(false);
  const [createQuestionsDrawer, openCreateQuestionsDrawer] = useState(false);
  const [passwordForgotDrawer, openPasswordForgotDrawer] = useState(false);
  const [profileDrawer, openProfileDrawer] = useState(false);
  const [multiplayerDrawer, openMultiplayerDrawer] = useState(false);
  const [gameId, setGameId] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [userData, setUserData] = useState({});
  const [showMenu, setShowMenu] = useState();
  const [isMobileView, setIsMobileView] = useState();
  const [selectedMenuEntry, setSelectedMenuEntry] = useState();

  const setUserLanguage = () => {
    const userLang = navigator.language || navigator.userLanguage;

    if (userLang.startsWith('de')) {
      actions.setLanguage('de');
    } else actions.setLanguage('en');
  };

  const getClientWidth = () => {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  };

  const toggleMenu = () => {
    const clientWidth = getClientWidth();

    if (clientWidth <= 1400) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  var doit;
  window.onresize = function () {
    clearTimeout(doit);
    doit = setTimeout(toggleMenu, 100);
  };

  const drawers = {
    login: { loginDrawer, openLoginDrawer },
    register: { registerDrawer, openRegisterDrawer },
    createQuestions: {
      createQuestionsDrawer,
      openCreateQuestionsDrawer,
    },
    navigation: { navigationDrawer, openNavigationDrawer },
    passwordForgot: { passwordForgotDrawer, openPasswordForgotDrawer },
    profile: {
      profileDrawer,
      openProfileDrawer,
    },
    multiplayer: {
      multiplayerDrawer,
      openMultiplayerDrawer,
      gameId,
      setGameId,
    },
  };

  const renderCurrentStatus = () => {
    if (store.status === STATUS.LoggedInAndVerified || store.status === STATUS.LoggedInByCode) {
      const onClickDropdownItem = ({ key }) => {
        switch (key) {
          case 'menu-item-profile':
            openProfileDrawer(true);
            break;
          case 'menu-item-logout':
            logoutUser(actions);
            break;

          default:
            return;
        }
      };

      const items = [
        {
          key: 'menu-item-profile',
          label: texts.contextMenu.profile[store.language],
          icon: <UserOutlined />,
        },
        {
          key: 'menu-item-logout',
          label: texts.contextMenu.logout[store.language],
          icon: <LogoutOutlined />,
        },
      ];

      return (
        <Space>
          <Text className='greeting-message'>Hi {store.user ? store.user.name : ''}!</Text>
          <Dropdown arrow trigger={['click']} menu={{ items, onClick: onClickDropdownItem }}>
            <Avatar className='avatarPlacement' key='avatar' size='large' src={`/api/avatar/${store.user.avatarFilename}`}>
              {!avatar && store.user.name ? store.user.name.charAt(0) : ''}
            </Avatar>
          </Dropdown>
          <Button
            className='avatarPlacement'
            type='primary'
            size='large'
            key='statusButton'
            shape='circle'
            icon={<PlusOutlined />}
            onClick={() => drawers.createQuestions.openCreateQuestionsDrawer(true)}
          ></Button>
        </Space>
      );
    } else {
      return (
        <Space className='avatarPlacement'>
          <Text className='greeting-message'>Hi {store.user ? store.user.name : ''}!</Text>
          <Avatar
            onClick={() => openLoginDrawer(true)}
            className='avatarPlacement filter--grayscale'
            key='avatar'
            size='large'
            src={`/api/avatar/${store.user.avatarFilename}`}
          >
            {!avatar && store.user.name ? store.user.name.charAt(0) : ''}
          </Avatar>
          <Button
            className='avatarPlacement'
            type='primary'
            size='large'
            key='statusButton'
            shape='circle'
            icon={<PlusOutlined />}
            onClick={() => drawers.createQuestions.openCreateQuestionsDrawer(true)}
          ></Button>
        </Space>
      );
    }
  };

  const switchLanguage = () => {
    if (store.language === 'en') actions.setLanguage('de');
    else actions.setLanguage('en');
  };

  const renderLanguageButton = () => {
    return (
      <Button
        icon={<GlobalOutlined />}
        style={{ left: '8px', bottom: '8px', position: 'absolute' }}
        onClick={() => {
          switchLanguage();
        }}
        size='small'
      >
        {store.language === 'en' ? 'de' : 'en'}
      </Button>
    );
  };

  useEffect(() => {
    const clientWidth = getClientWidth();

    if (showMenu && clientWidth <= 1000) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [showMenu]);

  useEffect(() => {
    setUserLanguage();
    toggleMenu();
    const currentURL = window.location.href;

    if (currentURL.includes('choicesOverview')) {
      setSelectedMenuEntry('choicesOverview');
    } else if (currentURL.includes('userRanking')) {
      setSelectedMenuEntry('userRanking');
    } else if (currentURL.includes('choicesEditor')) {
      setSelectedMenuEntry('choicesEditor');
    } else if (currentURL.includes('categoryEditor')) {
      setSelectedMenuEntry('categoryEditor');
    } else {
      setSelectedMenuEntry('home');
    }
  }, []);

  useEffect(() => {
    if (store.user && store.status !== STATUS.LoggedInByCode) {
      if (!localStorage.pestorcholera_token) {
        createUser(actions);
      } else {
        const token = localStorage.pestorcholera_token;

        let decoded = null;

        try {
          decoded = jwt_decode(token);
          const currentTime = Date.now() / 1000;

          console.log('current', new Date());
          console.log('decoded.exp', new Date(decoded.exp * 1000));
          console.log('user', decoded);

          if (decoded.exp < currentTime) {
            console.log('hello');
            logoutUser(actions);
            return;
          }
        } catch (e) {
          createUser(actions);
        }

        getProfile(actions, message);
      }
    }
  }, [actions]);

  useEffect(() => {
    console.log(store);
  }, [store]);

  return (
    <Router>
      <Layout style={{ height: '100%' }}>
        <Sider collapsed={!showMenu} theme='dark' trigger={null} collapsedWidth={0} collapsible={true} width={248} className='sideNavBar'>
          <div className='menuWrapper'>
            <Menu
              defaultOpenKeys={['Admin']}
              inlineIndent={16}
              selectedKeys={[selectedMenuEntry]}
              theme='dark'
              mode='inline'
              style={{ background: 'black', width: '100%', borderStyle: 'none', marginTop: '64px', paddingLeft: '8px', paddingRight: '8px' }}
              // defaultSelectedKeys={['1']}
              items={[
                {
                  key: 'home',
                  icon: <HomeOutlined />,
                  label: (
                    <Link
                      to='/'
                      onClick={() => {
                        drawers.navigation.openNavigationDrawer(false);
                        setSelectedMenuEntry('home');
                      }}
                    >
                      {texts.navigation.home[store.language]}
                    </Link>
                  ),
                },
                {
                  key: 'userRanking',
                  icon: <OrderedListOutlined />,
                  label: (
                    <Link
                      to='/userRanking'
                      onClick={() => {
                        drawers.navigation.openNavigationDrawer(false);
                        setSelectedMenuEntry('userRanking');
                      }}
                    >
                      {texts.navigation.userRanking[store.language]}
                    </Link>
                  ),
                },
                {
                  key: 'choicesOverview',
                  icon: <ReadOutlined />,
                  label: (
                    <Link
                      to='/choicesOverview'
                      onClick={() => {
                        drawers.navigation.openNavigationDrawer(false);
                        setSelectedMenuEntry('choicesOverview');
                      }}
                    >
                      {texts.navigation.choicesOverview[store.language]}
                    </Link>
                  ),
                },
                store && store.user && store.user.isAdmin
                  ? {
                      children: [
                        {
                          key: 'choicesEditor',
                          icon: <EditOutlined />,
                          label: (
                            <Link
                              to='/choicesEditor'
                              onClick={() => {
                                // drawers.navigation.openNavigationDrawer(false);
                                setSelectedMenuEntry('choicesEditor');
                              }}
                            >
                              {texts.navigation.editor[store.language]}
                            </Link>
                          ),
                        },
                        {
                          key: 'categoryEditor',
                          icon: <TagOutlined />,
                          label: (
                            <Link
                              to='/categoryEditor'
                              onClick={() => {
                                // drawers.navigation.openNavigationDrawer(false);
                                setSelectedMenuEntry('categoryEditor');
                              }}
                            >
                              {texts.navigation.categoryEditor[store.language]}
                            </Link>
                          ),
                        },
                      ],
                      label: 'Admin',
                      key: 'Admin',
                      icon: <DashboardOutlined />,
                    }
                  : '',
              ]}
            ></Menu>
            <img alt='logo' className='logo' src='/pestorcholera.png'></img>
          </div>

          {renderLanguageButton()}
          <div style={{ color: '#a3a3a3', right: '8px', bottom: '8px', position: 'absolute' }}>V: 0.0.7</div>
        </Sider>
        <Layout>
          <div
            onClick={() => {
              document.body.classList.remove('overflowHidden');
              document.querySelector('#root').classList.remove('overflowHidden');
              setShowMenu(false);
            }}
            className={isMobileView ? 'sideNavBarOverlay' : ''}
          ></div>
          <Header className='header'>
            {showMenu ? (
              <MenuFoldOutlined
                className='menuIcon'
                onClick={() => {
                  document.body.classList.remove('overflowHidden');
                  document.querySelector('#root').classList.remove('overflowHidden');
                  setShowMenu(false);
                }}
              />
            ) : (
              <MenuUnfoldOutlined
                onClick={() => {
                  document.body.classList.add('overflowHidden');
                  document.querySelector('#root').classList.add('overflowHidden');
                  setShowMenu(true);
                }}
                className='menuIcon'
              />
            )}

            <div className='dropdownWrapper'>{renderCurrentStatus()}</div>
          </Header>
          <Login drawers={drawers}></Login>
          <ForgotPassword drawers={drawers}></ForgotPassword>
          <Register drawers={drawers}></Register>
          <CreateQuestions drawers={drawers}></CreateQuestions>
          <Profile userData={userData} setAvatar={setAvatar} avatar={avatar} drawers={drawers}></Profile>
          <Content>
            <Routes>
              <Route
                path='/PasswordForgot/:email/:passwordForgotCode'
                element={<Home {...props} openLoginDrawer={openLoginDrawer} drawers={drawers} userData={userData} />}
              />
              <Route
                path='/ActivateAccount/:email/:verificationCode'
                element={<Home {...props} openLoginDrawer={openLoginDrawer} drawers={drawers} userData={userData} />}
              />
              <Route path='/Choices/:choicesMixId' element={<Home {...props} drawers={drawers} userData={userData} />} />
              <Route exact path='/' element={<Home {...props} openLoginDrawer={openLoginDrawer} drawers={drawers} userData={userData} />} />
              <Route exact path='/choicesOverview' element={<ChoicesOverview {...props} />} />
              <Route exact path='/choicesEditor' element={<ChoicesEditor {...props} />} />
              <Route exact path='/categoryEditor' element={<CategoryEditor {...props} />} />
              <Route exact path='/userRanking' element={<UserRanking {...props} />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default Navigation;

import React, { useState, useEffect, useContext } from 'react';
import { notification, message, Table, Button, Popconfirm, Space, Tag, Select, Input, FloatButton } from 'antd';
import '../../css/home.css';
import '../../css/table.css';
import { DeleteOutlined, QuestionCircleOutlined, SyncOutlined, PlusOutlined, TagOutlined } from '@ant-design/icons';
import { fetchWrapper } from '../../utils/fetchwrapper';
import { AppContext } from '../../contexts/ContextWrapper';

const { TextArea } = Input;

notification.config({
  placement: 'topRight',
});

const ChoicesEditor = (props) => {
  const { store, actions } = useContext(AppContext);
  const [choicesData, setChoicesData] = useState([]);
  const [options, setOptions] = useState([]);

  const handleDelete = (key) => {
    const newData = choicesData.filter((item) => item.choicesMixId !== key);
    setChoicesData([]);

    setTimeout(() => {
      setChoicesData(newData);
    }, 5);
  };

  useEffect(() => {
    fetchWrapper('/api/category', { method: 'GET' }).then((response) => {
      if (response.success && response.categories.length > 0) {
        setOptions(
          response.categories.map((category) => {
            return { value: category._id, label: category.en };
          }),
        );
      }
    });

    fetchWrapper('/api/choices', { method: 'POST' })
      .then(function (response) {
        if (response.success) {
          let choicesOverview = [];

          for (var i = 0; i < response.choices.length; i++) {
            let currentChoiceRow = response.choices[i];
            let choicesRow = {};

            choicesRow.categories = currentChoiceRow.categories?.map((category) => {
              return { value: category._id, label: category.en };
            });
            choicesRow.choicesMixId = currentChoiceRow.choiceOne.choicesMix;
            choicesRow.choiceOneId = currentChoiceRow.choiceOne._id;
            choicesRow.choiceTwoId = currentChoiceRow.choiceTwo._id;
            choicesRow.choiceOneEn = currentChoiceRow.choiceOne.en;
            choicesRow.choiceTwoEn = currentChoiceRow.choiceTwo.en;
            choicesRow.choiceOneDe = currentChoiceRow.choiceOne.de;
            choicesRow.choiceTwoDe = currentChoiceRow.choiceTwo.de;
            choicesRow.choiceOneUpVotes = currentChoiceRow.choiceOne.upVotes;
            choicesRow.choiceTwoUpVotes = currentChoiceRow.choiceTwo.upVotes;
            choicesRow.choiceOneComments = currentChoiceRow.choiceOne.comments ? currentChoiceRow.choiceOne.comments.length : 0;
            choicesRow.choiceTwoComments = currentChoiceRow.choiceTwo.comments ? currentChoiceRow.choiceTwo.comments.length : 0;
            choicesRow.createdBy = currentChoiceRow.createdBy ? currentChoiceRow.createdBy.name : null;

            choicesOverview.push(choicesRow);
          }

          setChoicesData(choicesOverview);
        } else {
          message.error('Choices could not be loaded.');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const updateChoicesMix = (record) => {
    // choicesMixId, choiceOneDe, choiceOneEn, choiceTwoDe, choiceTwoEn, choiceOneId, choiceTwoId, categories;
    const data = {
      choicesMixId: record.choicesMixId,
      choiceOneDe: record.choiceOneDe,
      choiceOneEn: record.choiceOneEn,
      choiceTwoDe: record.choiceTwoDe,
      choiceTwoEn: record.choiceTwoEn,
      choiceOneId: record.choiceOneId,
      choiceTwoId: record.choiceTwoId,
      categories: record.categories,
    };

    fetchWrapper('/api/choices', {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  const sortAlphabetically = (a, b) => {
    if (a.choiceOne.toLowerCase() < b.choiceOne.toLowerCase()) {
      return -1;
    }
    if (a.choiceOne.toLowerCase() > b.choiceOne.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const handleAdd = async () => {
    const choicesMix = await fetchWrapper('/api/choices/create', {
      method: 'POST',
      body: JSON.stringify({ createdByInterface: true }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const newData = {
      choicesMixId: choicesMix.choicesMixId,
      choiceOneId: choicesMix.choiceOneId,
      choiceTwoId: choicesMix.choiceTwoId,
      choiceOneEn: '',
      choiceTwoEn: '',
      choiceOneDe: '',
      choiceTwoDe: '',
      categories: [],
      createdBy: null,
    };

    setChoicesData([...choicesData, newData]);
  };

  const renderTextArea = (fieldName, value, choicesMixId) => {
    return (
      <TextArea
        onBlur={function (event) {
          const index = choicesData.findIndex((choicesMix) => choicesMix.choicesMixId === choicesMixId);
          choicesData[index][fieldName] = event.target.value;
          updateChoicesMix(choicesData[index]);
        }}
        defaultValue={value ? value : ''}
      />
    );
  };

  const columns = [
    {
      title: 'Choice One',

      children: [
        {
          title: 'Choice EN',
          dataIndex: 'choiceOneEn',
          key: 'choiceOneEn',
          sorter: (a, b) => sortAlphabetically(a, b),
          render: (text, record, index) => {
            return renderTextArea('choiceOneEn', record.choiceOneEn, record.choicesMixId);
          },
        },
        {
          title: 'Choice DE',
          dataIndex: 'choiceOneDe',
          key: 'choiceOneDe',
          sorter: (a, b) => sortAlphabetically(a, b),
          render: (text, record, index) => renderTextArea('choiceOneDe', record.choiceOneDe, record.choicesMixId),
        },
      ],
    },
    {
      title: 'Choice Two',
      children: [
        {
          title: 'Choice EN',
          dataIndex: 'choiceTwoEn',
          key: 'choiceTwoEn',
          sorter: (a, b) => sortAlphabetically(a, b),
          render: (text, record, index) => renderTextArea('choiceTwoEn', record.choiceTwoEn, record.choicesMixId),
        },
        {
          title: 'Choice DE',
          dataIndex: 'choiceTwoDe',
          key: 'choiceTwoDe',
          sorter: (a, b) => sortAlphabetically(a, b),
          render: (text, record, index) => renderTextArea('choiceTwoDe', record.choiceTwoDe, record.choicesMixId),
        },
      ],
    },
    {
      title: 'Kategorien',
      dataIndex: 'categories',
      key: 'categories',
      responsive: ['md'],
      width: 200,
      render: (text, record, index) => (
        <Select
          onChange={(value, options) => {
            choicesData[index]['categories'] = options;
            updateChoicesMix(choicesData[index]);
          }}
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Please select'
          options={options}
          defaultValue={
            record.categories
              ? record.categories.map((category) => {
                  return { value: category.value, label: category.label };
                })
              : []
          }
        />
      ),
    },

    {
      title: 'Autor',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) => sortAlphabetically(a, b),
      responsive: ['md'],
    },
    {
      dataIndex: 'operation',
      width: 8,
      render: (_, record) => (
        <Popconfirm
          title='Sure to delete?'
          onConfirm={() => {
            fetchWrapper(`/api/choices/${record.choicesMixId}`, {
              method: 'DELETE',
            });

            handleDelete(record.choicesMixId);
          }}
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className='tableContainer'>
      <FloatButton type='primary' style={{ right: 48, bottom: 48 }} onClick={handleAdd} icon={<PlusOutlined />} />
      <Table
        className='table'
        style={{ background: 'rgba(255, 255, 255, 0.2)' }}
        size='small'
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: 100 }}
        tableLayout='auto'
        bordered
        pagination={false}
        dataSource={choicesData}
        columns={columns}
      />
    </div>
  );
};

export default ChoicesEditor;

import React, { useState, useContext } from 'react';
import mime from 'mime';
import { Form, Input, Button, message, Drawer, Upload, Descriptions, Divider } from 'antd';
import { LockOutlined, PlusOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import { fetchWrapper } from '../utils/fetchwrapper';
import { AppContext } from '../contexts/ContextWrapper';
import '../css/login.css';
import '../css/profile.css';
const texts = require('../js/multilanguage');

const Profile = (props) => {
  const { store, actions } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [avatarRemoveDisplay, setAvatarRemoveDisplay] = useState(0);
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files!');
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error('Image must be smaller than 1MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      actions.setUser({ ...store.user, avatarFilename: store.user.id + '.' + mime.getExtension(info.file.type) });
      message.success('Avatar was uploaded.');
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className='ant-upload-text'>{texts.userManagement.upload[store.language]}</div>
    </div>
  );

  const onFinish = (values) => {
    setNewPassword(values.password, values.passwordConfirm);
  };

  const handleMouseEnter = () => {
    setAvatarRemoveDisplay(1);
  };

  const handleMouseLeave = () => {
    setAvatarRemoveDisplay(0);
  };

  const removeAvatar = () => {
    fetchWrapper('/api/profile/avatar', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'delete',
    }).then(function (result) {
      if (result.success === true) {
        actions.setUser({ ...store.user, avatarFilename: null });
        // actions.s
        // props.setAvatar(null);
        setLoading(false);
        setAvatarRemoveDisplay(0);
        message.success('Avatar was deleted.');
      } else {
        message.error('Could not upload avatar.');
      }
    });
  };

  const setNewPassword = (password, passwordConfirm) => {
    setPasswordResetLoading(true);

    fetchWrapper('/api/profile/password', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        password: password,
        passwordRepeat: passwordConfirm,
      }),
    }).then(function (result) {
      if (result.success) {
        message.success('New password was set.');
        form.resetFields();
      } else {
        message.error('Could not set new password');
      }
      setPasswordResetLoading(false);
    });
  };

  const changeHandler = (e) => {
    setFormErrors({});

    let currentFormValues = form.getFieldsValue();

    if (
      (typeof currentFormValues.password === 'undefined' || currentFormValues.password === '') &&
      (typeof currentFormValues.passwordRepeat === 'undefined' || currentFormValues.passwordRepeat === '')
    ) {
      form.resetFields();
    }
  };

  return (
    <Drawer
      forceRender
      getContainer={false}
      width={300}
      title={texts.headers.profile[store.language]}
      placement='right'
      closable={false}
      onClose={() => props.drawers.profile.openProfileDrawer(false)}
      open={props.drawers.profile.profileDrawer}
    >
      <div id='avatarContainer' style={{ position: 'relative' }}>
        <Upload
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
          width='200px'
          height='200px'
          name='avatar'
          multiple={false}
          listType='picture-card'
          className='avatar-uploader'
          showUploadList={false}
          action='/api/profile/updateAvatar'
          beforeUpload={beforeUpload}
          onChange={handleChange}
          headers={{ Authorization: localStorage.pestorcholera_token ? `Bearer ${localStorage.pestorcholera_token}` : undefined }}
        >
          {store.user && store.user.avatarFilename ? (
            <div className='profileAvatar' style={{ backgroundImage: 'url(/api/avatar/' + store.user.avatarFilename + ')' }}>
              {/* <img src={props.avatar} alt='avatar' style={{ width: '100%' }} /> */}
              <div
                onMouseOver={handleMouseEnter}
                onMouseOut={handleMouseLeave}
                onClick={(e) => {
                  if (store.user && store.user.avatarFilename) {
                    removeAvatar();
                    e.stopPropagation();
                  }
                }}
                className='avatarRemove'
                style={{ opacity: avatarRemoveDisplay }}
              >
                <div className='avatarRemoveIcon'>
                  <DeleteOutlined />
                </div>
              </div>
            </div>
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
      <Descriptions layout='horizontal' column={1}>
        <Descriptions.Item label={texts.userManagement.nickname[store.language]}>{store.user ? store.user.name : '-'}</Descriptions.Item>
        <Descriptions.Item label={texts.userManagement.email[store.language]}>{store.user ? store.user.mail : '-'}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Form
        size='middle'
        name='profile'
        className='login-form'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        form={form}
      >
        <Input.Group size='small'>
          <Form.Item
            name='password'
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || value.length < 6) {
                    return Promise.reject(texts.errors.passwordLength[store.language]);
                  }
                  return Promise.resolve();
                },
              }),
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if ('password' in formErrors) {
                    return Promise.reject(formErrors.password);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            hasFeedback
          >
            <Input onChange={changeHandler} type='password' placeholder={texts.userManagement.password[store.language]} prefix={<LockOutlined />} />
          </Form.Item>

          <Form.Item
            name='passwordConfirm'
            validateFirst={true}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(texts.errors.passwordMatch[store.language]);
                },
              }),
            ]}
          >
            <Input onChange={changeHandler} type='password' placeholder={texts.userManagement.confirmPassword[store.language]} prefix={<LockOutlined />} />
          </Form.Item>

          <Form.Item>
            <Button loading={passwordResetLoading} type='primary' htmlType='submit' className='login-form-button'>
              {texts.userManagement.passwordReset[store.language]}
            </Button>
          </Form.Item>
        </Input.Group>
      </Form>
    </Drawer>
  );
};

export default Profile;

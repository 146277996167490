import React, { useState, useEffect } from 'react';
import { notification, message, Table } from 'antd';
import '../css/home.css';
import '../css/table.css';
import { CommentOutlined, UserOutlined, FireOutlined } from '@ant-design/icons';
import { fetchWrapper } from '../utils/fetchwrapper';

notification.config({
  placement: 'topRight',
});

const ChoicesOverview = (props) => {
  const [choicesData, setChoicesData] = useState([]);

  useEffect(() => {
    fetchWrapper('/api/choices', { method: 'POST' })
      .then(function (response) {
        if (response.success) {
          let choicesOverview = [];

          for (var i = 0; i < response.choices.length; i++) {
            let currentChoiceRow = response.choices[i];
            let choicesRow = {};

            choicesRow.key = 'row_' + i;
            choicesRow.choiceOne = currentChoiceRow.choiceOne.en;
            choicesRow.choiceTwo = currentChoiceRow.choiceTwo.en;
            choicesRow.choiceOneUpVotes = currentChoiceRow.choiceOne.upVotes;
            choicesRow.choiceTwoUpVotes = currentChoiceRow.choiceTwo.upVotes;
            choicesRow.choiceOneComments = currentChoiceRow.choiceOne.comments ? currentChoiceRow.choiceOne.comments.length : 0;
            choicesRow.choiceTwoComments = currentChoiceRow.choiceTwo.comments ? currentChoiceRow.choiceTwo.comments.length : 0;
            choicesRow.createdBy = currentChoiceRow.createdBy ? currentChoiceRow.createdBy.name : null;

            choicesOverview.push(choicesRow);
          }

          setChoicesData(choicesOverview);
        } else {
          message.error('Choices could not be loaded.');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const sortAlphabetically = (a, b) => {
    if (a.choiceOne.toLowerCase() < b.choiceOne.toLowerCase()) {
      return -1;
    }
    if (a.choiceOne.toLowerCase() > b.choiceOne.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const columns = [
    {
      title: 'Choice One',
      children: [
        {
          title: 'Choice',
          dataIndex: 'choiceOne',
          key: 'choiceOne',
          sorter: (a, b) => sortAlphabetically(a, b),
          width: 130,
        },
        {
          title: <FireOutlined></FireOutlined>,
          dataIndex: 'choiceOneUpVotes',
          key: 'choiceOneUpVotes',
          sorter: (a, b) => a.choiceOneUpVotes - b.choiceOneUpVotes,
          width: 50,
        },
        {
          title: <CommentOutlined></CommentOutlined>,
          dataIndex: 'choiceOneComments',
          key: 'choiceOneComments',
          sorter: (a, b) => a.comments - b.comments,
          width: 50,
          responsive: ['md'],
        },
      ],
    },
    {
      title: 'Choice Two',
      children: [
        {
          title: 'Choice',
          dataIndex: 'choiceTwo',
          key: 'choiceTwo',
          sorter: (a, b) => sortAlphabetically(a, b),
          width: 130,
        },
        {
          title: <FireOutlined></FireOutlined>,
          dataIndex: 'choiceTwoUpVotes',
          key: 'choiceTwoUpVotes',
          sorter: (a, b) => a.choiceTwoUpVotes - b.choiceTwoUpVotes,
          width: 50,
        },
        {
          title: <CommentOutlined></CommentOutlined>,
          dataIndex: 'choiceTwoComments',
          key: 'choiceTwoComments',
          sorter: (a, b) => a.comments - b.comments,
          width: 50,
          responsive: ['md'],
        },
      ],
    },

    {
      title: <UserOutlined></UserOutlined>,
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) => sortAlphabetically(a, b),
      width: 100,
      responsive: ['md'],
    },
  ];

  return (
    <div className='tableContainer'>
      <Table
        className='table'
        style={{ background: 'rgba(255, 255, 255, 0.2)' }}
        size='small'
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: 100 }}
        tableLayout='auto'
        bordered
        pagination={false}
        dataSource={choicesData}
        columns={columns}
      />
    </div>
  );
};

export default ChoicesOverview;

import { message, notification } from 'antd';
import { Select } from 'antd';
import { fetchWrapper } from '../utils/fetchwrapper';
notification.config({
  placement: 'topRight',
});

const Option = Select.Option;

export const buildOptions = (store) => {
  if (store.categories)
    return store.categories.map((category) => (
      <Option value={category._id} key={category[store.language]} select={true}>
        {category[store.language]}
      </Option>
    ));
};

export const openNotificationWithIcon = (type, title, message, duration) => {
  if (typeof duration === 'undefined') duration = 7;

  notification[type]({
    message: title,
    description: message,
    duration: duration,
  });
};

export const calculateScore = (amountCommentsWritten, amountLikedChoices, amountCommentsUpvoted, amountChoicesCreated) => {
  return amountCommentsWritten * 10 + amountLikedChoices * 5 + amountCommentsUpvoted * 5 + amountChoicesCreated * 20;
};

export const canWriteComment = (store) => {
  if (store.amountLikedChoices <= 15) message.error('You have to like at least 15 choices.');
};

export const getProfile = async (actions, message) => {
  const response = await fetchWrapper('/api/profile', { method: 'GET' });
  if (response.success === true) {
    var data = response.userData;

    actions.setUser(data);
    actions.setStatus(response.status);
  } else {
    message.error('Could not retrieve user data');
  }
};

export const clearObject = (object) => {
  Object.keys(object).forEach((key) => {
    if (object[key] === null || object[key] === undefined) {
      delete object[key];
    }
  });

  return object;
};

import React, { useContext } from 'react';

import '../css/createQuestions.css';
import { Form, Input, Button, Drawer, message, Select, Alert, Space } from 'antd';
import { buildOptions } from '../js/helper';
import { AppContext } from '../contexts/ContextWrapper';
import { fetchWrapper } from '../utils/fetchwrapper';
const texts = require('../js/multilanguage');

const { TextArea } = Input;

const CreateQuestions = (props) => {
  const { store, actions } = useContext(AppContext);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    values.language = store.language;
    fetchWrapper('/api/choices/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then(function (response) {
        if (response.success) {
          actions.setUser({ ...store.user, amountChoicesCreated: store.user.amountChoicesCreated + 1 });
          message.success(texts.createQuestions.success[store.language]);
          form.resetFields();
          props.drawers.createQuestions.openCreateQuestionsDrawer(false);
        } else {
          message.error(<span class='message--text' dangerouslySetInnerHTML={{ __html: response.message.replaceAll('\n', '<br>') }}></span>);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  };

  return (
    <Drawer
      forceRender
      getContainer={false}
      width={300}
      title={texts.createQuestions.createQuestions[store.language]}
      placement='right'
      closable={false}
      onClose={() => props.drawers.createQuestions.openCreateQuestionsDrawer(false)}
      open={props.drawers.createQuestions.createQuestionsDrawer}
    >
      <Form
        form={form}
        name='create_questions'
        className='create-questions-form'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name='choiceOne'
          rules={[
            {
              required: true,
              message: texts.createQuestions.createQuestionOne[store.language],
            },
          ]}
        >
          <TextArea placeholder={texts.createQuestions.questionOne[store.language]} autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name='choiceTwo'
          rules={[
            {
              required: true,
              message: texts.createQuestions.createQuestionTwo[store.language],
            },
          ]}
        >
          <TextArea placeholder={texts.createQuestions.questionTwo[store.language]} autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name='categories'
          rules={[
            {
              required: true,
              message: texts.createQuestions.createQuestionsCategories[store.language],
            },
          ]}
        >
          <Select allowClear={true} className='select' placeholder={texts.createQuestions.categories[store.language]} mode='multiple'>
            {buildOptions(store)}
          </Select>
        </Form.Item>

        <Form.Item>
          {store.user && store.user.amountLikedChoices >= 15 && store.user.amountCommentsWritten >= 10 ? (
            <Button type='primary' htmlType='submit' className='login-form-button'>
              {texts.createQuestions.createQuestions[store.language]}
            </Button>
          ) : (
            <Space direction='vertical'>
              <Button disabled type='primary' className='login-form-button'>
                {texts.createQuestions.createQuestions[store.language]}
              </Button>

              <Alert
                message={
                  <>
                    {texts.minimumRequirements.likes[store.language]} <br></br> {` (${store.user.amountLikedChoices} / 15)`}
                  </>
                }
                type='info'
              />
              <Alert
                message={
                  <>
                    {texts.minimumRequirements.comments[store.language]} <br></br> {` (${store.user.amountCommentsWritten} / 10)`}
                  </>
                }
                type='info'
              />
            </Space>
          )}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateQuestions;

import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../contexts/ContextWrapper';
import { message, Avatar, Form, Button, Input, Divider, Typography, Card, Alert, Space } from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import { LikeOutlined, LikeFilled, TrophyOutlined } from '@ant-design/icons';
import '../css/comments.css';
import texts from '../js/multilanguage';
import { Tag } from 'antd';
import { calculateScore } from '../js/helper';
import { fetchWrapper } from '../utils/fetchwrapper';
const { Title } = Typography;

const { TextArea } = Input;

const Comments = ({ currentChoice }) => {
  const { store, actions } = useContext(AppContext);
  const [allComments, setAllComments] = useState([]);
  const [choicesPair, setChoicesPair] = useState({});
  const [topRatedChoiceOne, setTopRatedChoiceOne] = useState(null);
  const [topRatedChoiceTwo, setTopRatedChoiceTwo] = useState(null);

  const upvoteAction = (commentId, upVotes) => {
    let hasVoted = false;

    if (upVotes.includes(store.user.id)) hasVoted = true;

    return [
      <span
        key={'comment-basic-like' + commentId}
        onClick={() => {
          if (!hasVoted) upVoteComment(commentId);
        }}
      >
        {hasVoted ? <LikeFilled></LikeFilled> : <LikeOutlined></LikeOutlined>}
        <span style={{ marginLeft: '5px' }} className='comment-action'>
          {upVotes ? upVotes.length : 0}
        </span>
      </span>,
    ];
  };

  const handleSubmit = () => {
    if ((currentChoice && currentChoice.choiceOne.liked) || currentChoice.choiceTwo.liked) {
      let commentTextArea = document.getElementById('commentTextArea');
      let comment = commentTextArea.value;

      if (comment.length < 10) {
        message.error(texts.comments.tooShort[store.language]);
        return;
      }

      const commentInfo = {
        choicesMixId: currentChoice._id,
        comment: comment,
        choiceId: currentChoice.choiceOne.liked ? currentChoice.choiceOne._id : currentChoice.choiceTwo._id,
      };

      commentTextArea.value = '';

      fetchWrapper('/api/comments', {
        method: 'POST',
        body: JSON.stringify(commentInfo),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(function (response) {
        if (response.success === true) {
          actions.setUser({ ...store.user, amountCommentsWritten: store.user.amountCommentsWritten + 1 });
          getComments();
        } else {
          message.error(<span class='message--text' dangerouslySetInnerHTML={{ __html: response.message.replaceAll('\n', '<br>') }}></span>);
        }
      });
    } else {
      message.error(texts.comments.makeChoiceFirst[store.language]);
    }
  };

  useEffect(() => {
    if (currentChoice) {
      let choiceOneId = currentChoice.choiceOne._id;
      let choiceTwoId = currentChoice.choiceTwo._id;

      setChoicesPair({
        [choiceOneId]: currentChoice.choiceOne.color,
        [choiceTwoId]: currentChoice.choiceTwo.color,
      });
    }
  }, [currentChoice]);

  const Editor = ({ onSubmit }) => (
    <div>
      <Form.Item>
        <TextArea id='commentTextArea' autoSize={{ minRows: 4, maxRows: 4 }} />
      </Form.Item>
      <Form.Item>
        {store.user && store.user.amountLikedChoices >= 15 ? (
          <Button htmlType='submit' onClick={onSubmit} type='primary'>
            {texts.comments.add[store.language]}
          </Button>
        ) : (
          <>
            <Space direction='vertical'>
              <Button disabled type='primary'>
                {texts.comments.add[store.language]}
              </Button>
              <Alert message={texts.comments.minimumLikes[store.language] + ` (${store.user.amountLikedChoices}/15)`} type='info' />
            </Space>
          </>
        )}
      </Form.Item>
    </div>
  );

  const getComments = () => {
    fetchWrapper('/api/comments?choicesMixId=' + currentChoice._id, { method: 'GET' })
      .then(function (response) {
        if (response.success === true) {
          setAllComments(response.choicesMix.comments);

          if (response.choicesMix.choiceOneTopVotedComment && response.choicesMix.choiceTwoTopVotedComment) {
            setTopRatedChoiceOne(response.choicesMix.choiceOneTopVotedComment);
            setTopRatedChoiceTwo(response.choicesMix.choiceTwoTopVotedComment);
          } else {
            setTopRatedChoiceOne(null);
            setTopRatedChoiceTwo(null);
          }
        } else {
          setAllComments([]);
          message.error(texts.comments.couldNotReceive[store.language]);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  };

  const upVoteComment = (commentId) => {
    fetchWrapper('/api/comments/upVote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ commentId: commentId }),
    })
      .then(function (response) {
        if (response.success === true) {
          allComments.forEach((comment) => {
            if (comment._id === commentId) {
              comment.upVotes.push(store.user.id);
            }
          });

          actions.setUser({ ...store.user, amountCommentsUpvoted: store.user.amountCommentsUpvoted + 1 });

          setAllComments([...allComments]);
        } else {
          message.error(texts.comments.couldNotReceive[store.language]);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  };

  useEffect(() => {
    if (currentChoice && currentChoice._id !== null) {
      getComments();
    }
  }, [currentChoice]);

  const renderComment = (commentInfo, index, showDivider) => {
    if (!commentInfo || (commentInfo && !commentInfo.createdBy)) return null;

    const score = calculateScore(
      commentInfo.createdBy.amountCommentsWritten,
      commentInfo.createdBy.amountLikedChoices,
      commentInfo.createdBy.amountCommentsUpvoted,
      commentInfo.createdBy.amountChoicesCreated,
    );
    return (
      <div key={'div' + index}>
        <Comment
          key={'comment_' + index}
          actions={upvoteAction(commentInfo._id, commentInfo.upVotes)}
          author={
            <div>
              {commentInfo.createdBy.name}
              <Tag className='score__tag' icon={<TrophyOutlined></TrophyOutlined>} color='blue'>
                {score}
              </Tag>
            </div>
          }
          datetime={moment(commentInfo.date).format('DD. MMMM YYYY - hh:mm')}
          avatar={
            <div>
              <Avatar
                className='commentsAvatar'
                size={48}
                style={{
                  borderStyle: 'solid',
                  borderWidth: 4,
                  background: choicesPair ? choicesPair[commentInfo.choice] : 'none',
                  borderColor: choicesPair ? choicesPair[commentInfo.choice] : 'none',
                }}
                src={'/api/avatar/' + commentInfo.createdBy.avatarFilename}
                alt={commentInfo.createdBy.name}
              />
            </div>
          }
          content={<p>{commentInfo.comment}</p>}
        />
        {showDivider ? <Divider style={{ margin: 0 }}></Divider> : null}
      </div>
    );
  };

  return (
    <div
      style={{
        paddingTop: '24px',
        background: 'white',
        top: 0,
      }}
    >
      <Title level={3}>{texts.comments.header[store.language]}</Title>
      <Editor onSubmit={handleSubmit} />
      {topRatedChoiceOne && topRatedChoiceTwo ? (
        <div>
          <Title level={4}>{texts.comments.top[store.language]}</Title>
          <div className='cardWrapper'>
            <Card className='cardEntry'>{renderComment(topRatedChoiceOne, 0, false)}</Card>
            <Card className='cardEntry'>{renderComment(topRatedChoiceTwo, 1, false)}</Card>
          </div>
        </div>
      ) : null}
      <Title style={{ marginTop: 24 }} level={4}>
        {texts.comments.latest[store.language]}
      </Title>
      {allComments.length === 0
        ? texts.comments.notAvailable[store.language]
        : allComments.map((commentInfo, index) => {
            return renderComment(commentInfo, index, true);
          })}
    </div>
  );
};

export default Comments;

import { FilterOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../contexts/ContextWrapper';
import { buildOptions } from '../../js/helper';
import { Popover, Select, Button, message } from 'antd';

import { Row, Col } from 'antd';
import './filter.css';
import { useRef } from 'react';
const texts = require('../../js/multilanguage');

const Filter = ({ activeCategories, setActiveCategories }) => {
  const { store, actions } = useContext(AppContext);
  const [visibility, setVisibility] = useState(false);
  const [selection, setSelection] = useState([]);
  const ref = useRef();

  useEffect(() => {
    const closePopup = (event) => {
      if (!event.target.closest('#filter') && !event.target.closest('.ant-popover') && !event.target.closest('.rc-virtual-list')) {
        setVisibility(false);
      }
    };

    document.body.addEventListener('click', closePopup);

    return () => document.body.removeEventListener('click', closePopup);
  }, []);

  useEffect(() => {
    if (store.categories) prepareSelection(store.categories);
  }, [store.categories]);

  useEffect(() => {
    setSelection(activeCategories);
  }, [activeCategories]);

  const prepareSelection = (categories) => {
    const initialCategories = [];
    categories.forEach((category) => {
      initialCategories.push(category._id);
    });

    setSelection(initialCategories);
  };

  const updateSelectedOptions = (value) => {
    setSelection(value);
  };

  const applyFilter = () => {
    setVisibility(false);
    setActiveCategories(selection);
  };

  const select = (
    <div className='select--wrapper'>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Select
            ref={ref}
            value={selection}
            allowClear={true}
            onChange={updateSelectedOptions}
            className='select'
            placeholder={texts.filter.placeholder[store.language]}
            mode='multiple'
          >
            {buildOptions(store)}
          </Select>
        </Col>
        <Col>
          <Button
            onClick={() => {
              if (selection.length === 0) {
                message.error(texts.filter.error[store.language]);
                return;
              }
              applyFilter();
            }}
            type='primary'
          >
            {texts.filter.apply[store.language]}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setVisibility(false);
              setSelection(activeCategories);
            }}
            type='secondary'
          >
            {texts.filter.abort[store.language]}
          </Button>
        </Col>
      </Row>
    </div>
  );

  return (
    <div id='filter'>
      <Popover
        open={visibility}
        onOpenChange={() => setVisibility(true)}
        placement='bottomLeft'
        content={select}
        title={texts.filter.title[store.language]}
        trigger='click'
      >
        <FilterOutlined />
      </Popover>
    </div>
  );
};

export default Filter;

import React, { useState, useEffect, useContext } from 'react';
import { registerUser } from '../js/authHelper';
import { Form, Input, Checkbox, Button, Drawer } from 'antd';
import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { AppContext } from '../contexts/ContextWrapper';
const texts = require('../js/multilanguage');

const Registration = (props) => {
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState({});
  const { store, actions } = useContext(AppContext);

  const closePanel = () => {
    props.drawers.register.openRegisterDrawer(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    values.password = btoa(values.password);
    values.password2 = btoa(values.password2);
    await registerUser(values);
    closePanel();
  };

  const changeHandler = (e) => {
    setFormErrors({});

    let currentFormValues = form.getFieldsValue();

    if (
      (typeof currentFormValues.email === 'undefined' || currentFormValues.email === '') &&
      (typeof currentFormValues.password === 'undefined' || currentFormValues.password === '') &&
      (typeof currentFormValues.password2 === 'undefined' || currentFormValues.password2 === '') &&
      (typeof currentFormValues.name === 'undefined' || currentFormValues.name === '')
    ) {
      form.resetFields();
    }
  };

  useEffect(() => {
    setFormErrors(props.errors);
    console.log(props.errors);
  }, [props.errors]);

  useEffect(() => {
    let currentFormValues = form.getFieldsValue();

    if (typeof currentFormValues.email !== 'undefined' && currentFormValues.email !== '') {
      form.validateFields(['email']);
    }

    if (typeof currentFormValues.password !== 'undefined' && currentFormValues.password !== '') {
      form.validateFields(['password']);
    }

    if (typeof currentFormValues.name !== 'undefined' && currentFormValues.name !== '') {
      form.validateFields(['name']);
    }
  }, [formErrors]);

  return (
    <Drawer
      forceRender
      getContainer={false}
      width={300}
      title={texts.userManagement.signUp.text[store.language]}
      placement='right'
      closable={false}
      onClose={() => props.drawers.register.openRegisterDrawer(false)}
      open={props.drawers.register.registerDrawer}
    >
      <Form form={form} name='register' onFinish={onFinish} scrollToFirstError>
        <Form.Item
          name='email'
          validateFirst={true}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if ('email' in formErrors) {
                  return Promise.reject(formErrors.email);
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input onChange={changeHandler} placeholder='E-Mail' prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          name='name'
          validateFirst={true}
          rules={[
            {
              required: true,
              message: 'Please input your nickname!',
              whitespace: true,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if ('name' in formErrors) {
                  return Promise.reject(formErrors.name);
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input onChange={changeHandler} placeholder={texts.userManagement.nickname[store.language]} prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          name='password'
          validateFirst={true}
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || value.length < 6) {
                  return Promise.reject('Password has to contain at least 6 characters.');
                }
                return Promise.resolve();
              },
            }),
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if ('password' in formErrors) {
                  return Promise.reject(formErrors.password);
                }
                return Promise.resolve();
              },
            }),
          ]}
          hasFeedback
        >
          <Input type='password' placeholder={texts.userManagement.password[store.language]} prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item
          name='password2'
          validateFirst={true}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('The two passwords that you entered do not match!');
              },
            }),
          ]}
        >
          <Input type='password' placeholder={texts.userManagement.confirmPassword[store.language]} prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item
          name='agreement'
          validateFirst={true}
          valuePropName='checked'
          rules={[
            {
              required: true,
              message: 'Please accept the agreement',
            },
          ]}
        >
          <Checkbox>{texts.userManagement.signUp.agreement[store.language]}</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {texts.userManagement.signUp.text[store.language]}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Registration;

import React, { useState, useEffect, useContext } from 'react';
import { notification, message, Table, Button, Popconfirm, Space, Tag, Select, Input, FloatButton } from 'antd';
import '../../css/home.css';
import '../../css/table.css';
import { DeleteOutlined, QuestionCircleOutlined, SyncOutlined, PlusOutlined, TagOutlined } from '@ant-design/icons';
import { fetchWrapper } from '../../utils/fetchwrapper';
import { AppContext } from '../../contexts/ContextWrapper';

const { TextArea } = Input;

notification.config({
  placement: 'topRight',
});

const CategoryEditor = (props) => {
  const { store, actions } = useContext(AppContext);
  const [categoryData, setCategoryData] = useState([]);
  const [options, setOptions] = useState([]);

  const handleDelete = (key) => {
    const newData = categoryData.filter((item) => item._id !== key);
    setCategoryData([]);

    setTimeout(() => {
      setCategoryData(newData);
    }, 5);
  };

  useEffect(() => {
    fetchWrapper('/api/category', { method: 'GET' }).then((response) => {
      if (response.success && response.categories.length > 0) {
        setCategoryData(response.categories);
      }
    });
  }, []);

  const updateCategories = (record) => {
    const data = {
      _id: record._id,
      de: record.de,
      en: record.en,
    };

    fetchWrapper('/api/category', {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  const sortAlphabetically = (a, b) => {
    if (a.choiceOne.toLowerCase() < b.choiceOne.toLowerCase()) {
      return -1;
    }
    if (a.choiceOne.toLowerCase() > b.choiceOne.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const handleAdd = async () => {
    const response = await fetchWrapper('/api/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const newData = {
      _id: response.categoryId,
      en: '',
      de: '',
    };

    setCategoryData([...categoryData, newData]);
  };

  const renderTextArea = (fieldName, value, categoryId) => {
    return (
      <TextArea
        onBlur={function (event) {
          const index = categoryData.findIndex((choicesMix) => choicesMix._id === categoryId);
          categoryData[index][fieldName] = event.target.value;
          updateCategories(categoryData[index]);
        }}
        defaultValue={value ? value : ''}
      />
    );
  };

  const columns = [
    {
      title: 'Category EN',
      dataIndex: 'en',
      key: 'en',
      sorter: (a, b) => sortAlphabetically(a, b),
      render: (text, record, index) => {
        return renderTextArea('en', record.en, record._id);
      },
    },
    {
      title: 'Category DE',
      dataIndex: 'de',
      key: 'de',
      sorter: (a, b) => sortAlphabetically(a, b),
      render: (text, record, index) => renderTextArea('de', record.de, record._id),
    },

    {
      dataIndex: 'operation',
      width: 8,
      render: (_, record) => (
        <Popconfirm
          title='Sure to delete?'
          onConfirm={() => {
            fetchWrapper(`/api/category/${record._id}`, {
              method: 'DELETE',
            });

            handleDelete(record._id);
          }}
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className='tableContainer'>
      <FloatButton type='primary' style={{ right: 48, bottom: 48 }} onClick={handleAdd} icon={<PlusOutlined />} />
      <Table
        className='table'
        style={{ background: 'rgba(255, 255, 255, 0.2)' }}
        size='small'
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: 100 }}
        tableLayout='auto'
        bordered
        pagination={false}
        dataSource={categoryData}
        columns={columns}
      />
    </div>
  );
};

export default CategoryEditor;

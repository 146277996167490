import { fetchWrapper } from '../utils/fetchwrapper';
import jwt_decode from 'jwt-decode';
import { STATUS } from '../constants/enums';
import { getProfile } from './helper';
import { message } from 'antd';

export const registerUser = async (userData) => {
  console.log(userData);
  const params = new URLSearchParams(window.location.search);
  fetchWrapper('/api/auth/register?adminkey=' + params.get('adminkey'), {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(userData),
  }).then(function (result) {
    if (!result.success) {
      const errorMessages = [];

      for (const key in result) {
        if (key !== 'success') {
          errorMessages.push(result[key]);
        }
      }
      message.error(errorMessages.join(' and '));
    }
  });
};

//create anonymous user
export const createUser = (actions) => {
  fetchWrapper('/api/auth/createUser', {
    method: 'POST',
  }).then(function (result) {
    if (result.success) {
      localStorage.setItem('pestorcholera_token', result.token);
      actions.setUser(result.user);
      actions.setStatus(result.status);
    }
  });
};

// Login - get user token
export const loginWithCode = (userData, actions, closePanel) => {
  return fetchWrapper('/api/auth/loginWithCode', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(userData),
  });
};

export const resetPassword = (userData, actions, closePanel) => {
  fetchWrapper('/api/auth/resetPassword', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(userData),
  });
};

// Login - get user token
export const loginUser = (userData, actions, closePanel) => {
  fetchWrapper('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  }).then(function (result) {
    if (result.success) {
      const token = result.token;
      const decoded = jwt_decode(token);
      actions.setUser(decoded.user);
      actions.setStatus(decoded.status);
      localStorage.setItem('pestorcholera_token', token);
      if (closePanel) {
        closePanel();
        getProfile(actions, message);
      }
    }
  });
};

export const logoutUser = (actions) => {
  fetchWrapper('/api/auth/logout', {
    method: 'POST',
  }).then(function (result) {
    if (result.success) {
      localStorage.removeItem('pestorcholera_token');
      localStorage.setItem('pestorcholera_token', result.token);
      getProfile(actions, message);
    }
  });
};
